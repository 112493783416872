/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    $(".realisation").click(function(index) {
        $(".realisation_image_large").fadeOut(0);
        $(this).next().fadeIn(200);
        var margB = Number($(this).css("marginBottom").replace("px", ""));
        
        var currentItemHeight = ($(this).find(".realisation_image").height() + margB) * -1;
        

        $(this).next().css("marginTop", currentItemHeight + "px");

        var logoMobileHeight = 0;
        if($(".logo_mobile").css('display') == "block"){
            logoMobileHeight = $(".logo_mobile").height();
        }
        

        $('html,body').animate({scrollTop:$(this).offset().top - logoMobileHeight - 20}, 500);


    });

    $(".realisation_image_large i ").click(function(index) {
        $(this).parent().parent().fadeOut(200);
    });

    var isOpen = false;
    document.querySelector("#nav-toggle")
        .addEventListener("click", function() {
            this.classList.toggle("active");
            if (!isOpen) {
                isOpen = true;
                $(".nav-primary").animate({
                    height: "340px",
                    border: "2px solid red"
                }, 200);
            } else {
                isOpen = false;
                $(".nav-primary").animate({
                    height: 0
                }, 200);

            };
        });


$('.nav-primary a[href*=#]').on('click', function(event){     
    event.preventDefault();
  
    document.querySelector("#nav-toggle").click();

  var logoMobileHeight = 0;
        if($(".logo_mobile").css('display') == "block"){
            logoMobileHeight = $(".logo_mobile").height();
        }
        


    $('html,body').animate({scrollTop:$(this.hash).offset().top - logoMobileHeight - 10}, 500);
});




    $(".various").fancybox({
        maxWidth    : 800,
        maxHeight   : 600,
        fitToView   : false,
        width       : '70%',
        height      : '70%',
        autoSize    : false,
        closeClick  : false,
        openEffect  : 'none',
        closeEffect : 'none'
    });





})(jQuery); // Fully reference jQuery after this point.
